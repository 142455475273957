import PreviousCounter from 'utilities/PreviousCounter';

export default function JourneyCsvData(onOutcomeCsv, name, uuid, uuidToOutcomeName) {
    return async () => {
        let journeys = await onOutcomeCsv({name: name, uuid: uuid});
        console.log(journeys);
        const piecesOfName = name.split(':');
        let csvData = [];
        journeys.forEach((journey) => {
            const {title, occurrences, series, extra, steps, links, tags} = journey;
            const platform = extra ? extra.platform : {softwareVersion:'', deviceModel:'', operatingSystemVersion:''};
            const {softwareVersion, deviceModel, operatingSystemVersion} = platform;

            const journeyCategory = piecesOfName.length > 1 ? piecesOfName[0] : '';
            const result = piecesOfName.length > 1 ? piecesOfName[1] : '';
            const name = piecesOfName[piecesOfName.length-1];
            const topTimestamp = series.at(-1);
            const formattedTags = formatTags(tags);

            const data = [title, occurrences, name, '', '', '', '0', topTimestamp, softwareVersion, deviceModel, operatingSystemVersion, journeyCategory, result, links, formattedTags, '', ''];
            csvData.push(data);
            let totalSteps = steps.length;
            let previouses = 0;
            if (totalSteps > 0) {
                const last = steps[totalSteps-1];
                const {outcome} = last;
                if (outcome){
                    totalSteps = totalSteps - 1;
                }
            }
            steps.forEach((step, sequence) => {
                step.action = (typeof step.action === 'string' || step.action instanceof String) ? step.action.replace(/,/g, '') : step.action; //remove all commas from the action
                const {outcome, delta, category, action, count = 1, previous, timestamp, name, type, identifier} = step;
                const detail = name ? name : type ? type : '';
                if (outcome) return;
                if (previous) {
                    const counts = PreviousCounter(previous);
                    totalSteps = totalSteps + counts.length - 1;
                    counts.forEach((step, index) => (csvData.push([
                        journey.title,
                        step.count(),
                        uuidToOutcomeName[step.item()],
                        '',
                        '',
                        index,
                        totalSteps-index,
                        timestamp, softwareVersion, deviceModel, operatingSystemVersion, journeyCategory, '',
                        links, formattedTags, detail, (identifier?identifier:'')
                    ])));
                    previouses = counts.length-1;
                    return;
                }
                const index = sequence + previouses;
                csvData.push([
                    journey.title,
                    count,
                    category,
                    action,
                    delta > 0 ? delta : 0 ,
                    index,
                    totalSteps-index,
                    timestamp, softwareVersion, deviceModel, operatingSystemVersion, journeyCategory, '',
                    links, formattedTags, detail, (identifier?identifier:'')
                ]);
            });
        });
        return csvData;
    };
}

export function formatTags(tags) {
    if (!tags) return tags;
    tags = Object.fromEntries(Object.entries(tags).filter((tag) => {
       return tag[1].length > 0;
    }));

    if (Object.keys(tags).length === 0) {
        return undefined;
    }

    return JSON.stringify(tags).replaceAll(',', ';');
}